import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs/index";
import {Alert} from "./alert.model";

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    private alertSource = new BehaviorSubject(new Alert());
    currentAlert = this.alertSource.asObservable();

    constructor() {
    }

    addAlert(alert: Alert) {
        this.alertSource.next(alert)
    }
}
