import { Component, OnInit } from '@angular/core';
import {AlertService} from "../alert.service";

interface Alert {
    type: string;
    message: string;
}

const ALERTS: Alert[] = [];

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

    public alerts: Alert[] = [];

    constructor(
        private alertService: AlertService
    ) {
    }

    ngOnInit() {
        this.alertService.currentAlert.subscribe(alert => this.alerts.unshift(alert));
        this.reset();
    }

    close(alert: Alert) {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }

    reset() {
        this.alerts = [];
    }

}
