import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {NgbTypeaheadSelectItemEvent} from "@ng-bootstrap/ng-bootstrap";
import {ContactService} from "../contact.service";
import {catchError, switchMap, tap} from "rxjs/internal/operators";
import {Contact} from "../contact.model";

@Component({
    selector: 'app-typeahead',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./typeahead.component.css']
})
export class TypeaheadComponent implements OnInit, OnChanges {

    private searchFailed: boolean;
    private searching: boolean;
    private model: string;

    constructor(private contactService: ContactService) {
    }

    ngOnInit() {
        this.contactService.clearContact.subscribe(() => this.model = null);
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term =>
                this.contactService.getContacts(term).pipe(
                    tap(() => this.searchFailed = false),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([{
                            alias: 'Brak wyników',
                            address_street: '',
                            address_street_number: '',
                            address_postalcode: '',
                            address_city: ''
                        }]);
                    }))
            ),
            tap(() => this.searching = false)
        )


    onSelectItem(event: NgbTypeaheadSelectItemEvent): void {
        this.contactService.changeContact(event.item);
    }

    formatter = (x: { alias: string }) => x.alias;

}