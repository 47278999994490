import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import { FormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { PacztexformComponent } from './pacztexform/pacztexform.component';
import {HttpClientModule} from "@angular/common/http";
import { AlertComponent } from './alert/alert.component';

@NgModule({
    declarations: [
        AppComponent,
        TypeaheadComponent,
        PacztexformComponent,
        AlertComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        NgbModule,
        HttpClientModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
