import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from "rxjs";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Contact} from "./contact.model";

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    private contactSource = new BehaviorSubject(new Contact());
    private clearContactSource = new BehaviorSubject(null);
    currentContact = this.contactSource.asObservable();
    clearContact = this.clearContactSource.asObservable();

    constructor(private http: HttpClient) {
    }

    getContacts(query) {
        if (query.length < 3) {
            return of([]);
        }
        return this.http.get("https://www.butelki-plastikowe.pl/pacztex/index.php?action=contacts&q=" + query).pipe()
    }

    changeContact(contact: Contact) {
        this.contactSource.next(contact)
    }

    clear(clear) {
        this.contactSource.next(new Contact());
        this.clearContactSource.next(true);
    }

}
