export class Contact {
    id: number;
    alias: string;
    company: string;
    first_name: string;
    last_name: string;
    address_street: string;
    address_street_number: string;
    address_postalcode: string;
    address_city: string;
    number: string;
    email: string;
    comments: string;
    updated: string;
    quantity: number;
    cod: number;
    insurance: number;
    cod_enabled: boolean;
    insurance_enabled: boolean;
    insurance_real_enabled: boolean;
}
