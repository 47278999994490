import {Component, OnInit} from '@angular/core';
import {Contact} from "../contact.model";
import {ContactService} from "../contact.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../alert.service";

@Component({
    selector: 'app-pacztexform',
    templateUrl: './pacztexform.component.html',
    styleUrls: ['./pacztexform.component.css']
})
export class PacztexformComponent implements OnInit {

    public model: Contact;

    constructor(
        private contactService: ContactService,
        private http: HttpClient,
        private alertService: AlertService
        ) {
    }

    ngOnInit() {
        this.contactService.currentContact.subscribe(contact => this.model = contact);
    }

    codChanged(state) {
        this.model.insurance_enabled = state;
        this.model.insurance_real_enabled = false;

        if (!state) {
            this.model.cod = null;
            this.model.insurance = null;
        }
    }

    insuranceChanged(state) {
        if (this.model.cod_enabled) {
            this.model.insurance_real_enabled = false;
        } else {
            this.model.insurance_real_enabled = state;
        }

        if (!state) {
            this.model.insurance = null;
        }
    }

    codValueChanged(value) {
        this.model.cod = value;
        this.model.insurance = value;
    }

    onSubmit(contact: Contact){
        this.http.post("https://www.butelki-plastikowe.pl/pacztex/index.php?action=send", contact).subscribe(alert => this.addAlert(alert), error => this.error(error));
        this.contactService.clear(true);
    }

    onNew() {
        this.contactService.clear(true);
    }

    addAlert(alert) {
        this.alertService.addAlert(alert);
    }

    error(alert) {
        console.log(alert);
        this.addAlert({
            type : 'danger',
            message: 'Coś poszło nie tak: ' + alert.message
        });
    }

}
